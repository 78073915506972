<script setup lang="ts">
  import HeaderAll from "~/components/globals/Header/HeaderAll.vue";
  import SectionPartners from "~/components/sections/SectionPartners/SectionPartners.vue";
  import Footer from "~/components/globals/Footer/Footer.vue";

  const apiLoaded = inject('pageLoaded', true)
</script>

<template>
<div>
  <HeaderAll />
  <slot name="sticky_position"></slot>
  <main>
    <slot name="body_page"></slot>
  </main>
  <template v-if="apiLoaded">
    <SectionPartners/>
    <Footer />
  </template>
</div>
</template>

<style scoped>

</style>
